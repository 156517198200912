$(document).ready(function () {
	customFilterMobileDropdown();
	initEvents();
	initMobileLogo();
	initSplitSlider();
	initPagination();
    initVideoPopups();
    initBooking();
    initHeader();
    initPrivateDiningForm();
    // initLottieAnimations();
    initBackgroundVideos();
    initLangDropdown();
	// customCookieBar();
// 
});

function initPrivateDiningForm() {
    $('.contact__form form select').selectric();
}

function initLangDropdown() {
	$('.header__lang button').on('click', function() {
		$('.header__lang').toggleClass('open');
	});
}

var initFeaturedCarousel = function () {
	$('.featured-carousel__inner').slick({
		dots: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1
	})
}

var initSlider = function () {
	$('.slider__inner').reformSlider({
		autoPlay: (function () {
			if ( $('.slider__slide iframe').length > 0 ) {
				return false
			} else {
				return true
			}
		})(),
		speed: 7000,
		arrows: true,
		pagination: true,
		onLoad: function () {
			$('.has-mixed-media').addClass('show')
		}
	});
	
	if ( $('.slider__slide iframe').length > 0 ) {
		$('.slider').addClass('has-video');
		coverViewport('.slider__slide iframe', true);
		$(window).on('resize', function () {
			coverViewport('.slider__slide iframe', true);
		});
	}
    
    if ( $('.slider__offer').length > 0 ) {
        $('.slider').addClass('has-offer');
        
        scaleSliderOfferImages();
        $(window).on('resize', scaleSliderOfferImages);
    }
};


function scaleSliderOfferImages() {
    if ( window.innerWidth < 640 ) {
        $('.slider__offer').each(function () {
            $(this).prev('picture').css('height', (window.innerHeight - $(this).outerHeight()) + 'px');
        });
    } else {
        $('.slider picture').attr('style', '');
    }
}


var initLandingSlider = function () {	
	$('.landing__slider-inner').slick({
		fade: true,
		arrows: true,
		autoplay: false
	});
	
	if ( ($('.landing__row').last().hasClass('landing__row--light-background')) || ($('.landing__row').last().hasClass('landing__row--dark-background')) ) {
		$('.landing').addClass('landing--last-item-has-background-color');
	}
};


var initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		if ( $(this).hasClass('in-view') ) {
			return;	
		}
		
		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;
			
			$(elementToAnimate).addClass('in-view'); // log that this element has been seen
			
			
			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
				$(elementToAnimate).removeClass( $(elementToAnimate).data('animate-in-view-remove-class') ); // remove the pre-animation class
			}, 300);
			
			
			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	
	var rect = el.getBoundingClientRect();
	
	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;
	
	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}


var initHeader = function () {
  function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;
	
	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background

	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	function setHeaderTransparency() {
        if (window.pageYOffset < 30) {
            $('.header').addClass('header--transparent');
        } else {
            $('.header').removeClass('header--transparent');
        }
	}
	
	// Show the burger nav when there isn't enough room for the horizontal nav

	if ( $('.header__horizontal-nav').length > 0 ) {
		$(window).on('resize', function () {
			throttle(function (event) {
				console.log('resizing2');
			}, 1500);
		});
	}
}


var initMaps = function () {
	$('[data-map]').each(function () {
		buildMap(this);
	});
}

function buildMap(mapContainer) {
	var $mapContainer = $(mapContainer);
	var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
	
	if (typeof $mapContainer.data('map-center-coords') != 'undefined') {
		centerLat = parseFloat( $mapContainer.data('map-center-coords').split(',')[0] );
		centerLng = parseFloat( $mapContainer.data('map-center-coords').split(',')[1] );
	} else {
		centerLat = 0;
		centerLng = 0;
	}
	
	zoom = $mapContainer.data('map-zoom') || 8;
	
	map = new google.maps.Map($mapContainer[0], {
		center: {lat: centerLat, lng: centerLng},
		zoom: zoom,
		zoomControl: true,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.SMALL,
			position: google.maps.ControlPosition.RIGHT_CENTER
		},
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: false,
		styles: mapDarkStyle
	});
	
	if (typeof $mapContainer.data('map-marker-coords') != 'undefined') {
		if (typeof $mapContainer.data('map-marker-coords') != 'undefined') {
			markerLat = parseFloat( $mapContainer.data('map-marker-coords').split(',')[0] );
			markerLng = parseFloat( $mapContainer.data('map-marker-coords').split(',')[1] );
		} else {
			markerLat = 0;
			markerLng = 0;
		}

		marker = new google.maps.Marker({
			position: {lat: markerLat, lng: markerLng},
			map: map
		});
		
		if (typeof $mapContainer.data('map-marker-url') != 'undefined') {
			marker.setIcon({
				url: $mapContainer.data('map-marker-url'),
				origin: new google.maps.Point(0,0),
				anchor: new google.maps.Point(125,78)
			});
		}
	}
	

	
	
	$mapContainer.siblings('[data-map-marker]').each(function () {
		if (typeof $(this).data('map-marker-coords') != 'undefined') {
			markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
			markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
		} else {
			markerLat = 0;
			markerLng = 0;
		}
		
		marker = new google.maps.Marker({
			position: {lat: markerLat, lng: markerLng},
			map: map
		});
		
		if (typeof $(this).data('map-marker-url') != 'undefined') {
			marker.setIcon($(this).data('map-marker-url'));
		}
	});
	
	
	var old_v='',
		ds=new google.maps.DirectionsService(),
		dr=new google.maps.DirectionsRenderer();
	  dr.setMap(map);
	  dr.setPanel(jQuery('#map-directions-data').get(0))
	
	$('.location__form').on('submit', function (e) {
		e.preventDefault();
		
		var v=jQuery.trim( $('input[name="directions_from"]').val() );
		
		if(v!=''&&v!=old_v){
			old_v=v;  
				
			var r = {
				origin:v,
				destination: '1731 New Hampshire Ave NW, Washington, DC 20009',
				travelMode: google.maps.TravelMode.DRIVING,
				unitSystem: google.maps.UnitSystem.IMPERIAL
			};
			
			ds.route(r, function(result, status){        
		
				if (status == google.maps.DirectionsStatus.OK) {
					marker.setMap(null);
					dr.setDirections(result);
			
					setTimeout(function () {
						var x = document.getElementById("map-directions-data").innerHTML;
						var str = x;
						str = str.replace(/&/g, "amp;");
						str = str.replace(/>/g, "gt;");
						str = str.replace(/</g, "lt;");
						str = str.replace(/"/g, "quot;");
						str = str.replace(/'/g, "#039;");
					},2000);
				} else {
					alert('Location ' + old_v + ' not found.');
				}
			});      
		}
	});
}


var mapDarkStyle = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2e3e1"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#94a4ae"},{"visibility":"on"}]}];


function initEvents() {
	$('.events__slider').slick({
		slidesToShow: 3,
		infinite: true,
		arrows: true,
		responsive: [
            {
                breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
            },
			{
                breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}


function customFilterMobileDropdown() {
	if ( $('.gallery').length === 0 ) return;
	
	var filterWatcher = setInterval(function () {
		if ( $('.filter-controls-dropdown select').length === 1 ) {
			clearInterval(filterWatcher);
		}
		$('.filter-controls-dropdown select').selectric();
	}, 500);
}

var initAccordions = function() {

    $('.accordion__inner').accordiom({
        showFirstItem: false
    })
}


var initOtherRooms = function () {
	$('.other-rooms__carousel').slick({
		dots: true,
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 0,
		infinite: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true
				}
			}
		]
	});
}


var initAccommodation = function () {
	$('.accommodation__slider-inner').slick({
		fade: true,
		arrows: true,
		autoplay: false
	});
	
	// Apply odd/even styles that skip any quote or other sections added in between
	var i = 1;
	$('.accommodation__item').each(function () {
		if ( i < 0 ) {
			$(this).addClass('accommodation__item--odd');
		} 
		
		i = i*-1;
	});
}



function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function initMobileLogo() {
    
    setTimeout(function () {
        $('.header').addClass('visible');
    }, 300);

    $(window).on('scroll', function() {
        var docHeight = $('main').outerHeight();
        var windowHeight = $(window).outerHeight();
        var topOffset = $(document).scrollTop();

        if( $('body').hasClass('home') ) {

            if( ( topOffset > 200 ) && ( $(window).outerWidth() < 1024 ) ) {
                $('.header').addClass('header-scrolled');
            } else {
                $('.header').removeClass('header-scrolled');
            }
        }
    })

}

function initSplitSlider() {
	$('.split-slider__inner').slick({
		arrow: true,
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: 'progressive'
	})
}

function initPagination() {
	$('.pagination:last').addClass('last');
}



function initVideoPopups() {
    var $modal;
    var modal = '<div id="gallery__modal" class="gallery__modal gallery__modal--video full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';
        
    
    // Detect video links and embed them in a popup
    $('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
        $modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );
        
        $('body').append($modal);
        
        $('#gallery__modal').foundation();
        $('#gallery__modal').foundation('open');
        
        $('#gallery__modal').on('closed.zf.reveal', function () {
            $modal.remove();
        });
        
        return false;
    });
}


function generateVideoEmbedUrl(videoUrl) {
    if (videoUrl.indexOf('youtube.com/embed') > -1) {
        // already a YouTube embed code, return same link
        return videoUrl;
    } else if (videoUrl.indexOf('youtu') > -1) {
        // must be either a full or shortened YouTube url so parse and create embed link
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = videoUrl.match(regExp);
    
        if (match && match[2].length == 11) {
            return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
        } else {
            return videoUrl;
        }		
    } else if (videoUrl.indexOf('player.vimeo.com') > -1) {
        // already a Vimeo embed code, but add some parameters
        if ( videoUrl.indexOf('?') > -1 ) {
            videoUrl += '&';
        } else {
            videoUrl += '?';
        }
        return videoUrl + 'autoplay=true&byline=false&portrait=false&title=false';
    } else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
        // normal Vimeo link, so generate and embed code
        return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
    }
    
    // if all else fails, return the original link
    return embedUrl;
}


function initBooking() {
    $('a[href="#book-table"]').on('click', function () {
        $('.booking iframe').each(function () {
            if ( typeof $(this).data('src') !== 'undefined' ) {
                $(this).attr('src', $(this).data('src'));
            }
        });
        
        $('#booking').foundation('open');
       
       return false; 
    });
}

function initLottieAnimations () {

    var pathCorkscrew = '/assets/animations/corkscrew.json'
    var pathBarrel = '/assets/animations/fish-barrel.json'
    var pathTriple = '/assets/animations/triple.json'
    var pathWiggleTail = '/assets/animations/wiggletail.json'

    var arrDinignRoomsIcons = $(".dining-rooms__item-logo");
    var arrCards = $('.cards__card')
    var arrCardImgs = $(".card-img")

    var headerLogo = {
        container: $('.header .header__logo a.light')[0],
        path: '/assets/animations/fish-header-logo.json'
    }

    var burgerIcon = {
        container: $('.header__off-canvas-toggle')[0],
        path: '/assets/animations/menu-icon.json'
    }

    var menusMask = {
        container: $('.img-mask'),
        path: '/assets/animations/wave-mask.json'
    }
  
    function getRandomInt(max) {
        return Math.floor(Math.random() * max) * 1000;
    }

    function setAnimate(container, path, loop) {
        if (loop === undefined) {
            loop = false
        }
        var instance = bodymovin.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: loop,
            autoplay: false,
            path: path,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            }
        })

        return instance
    }

    function animate (instance, start) {
        instance.addEventListener('complete', function () {
            var num = 1
            instance.stop()
            var times = setTimeout(function () {
                num++
                instance.play()
            }, getRandomInt(10))          
        })
        instance.pause()
        setTimeout(function () {
            instance.play()
        }, start)
    }

    function handleHover(arrContainer, arrParent) {
        var instances = []
        arrContainer.each(function () {
            var instance = setAnimate($(this)[0], pathWiggleTail, true)
            instance.pause()
            instances.push(instance)
            
        })
        
        arrParent.each(function (index) {
            $(this).on('mouseenter',  function () {
                instances[index].play()
            })
            $(this).on('mouseleave',  function () {
                instances[index].pause()
            })

        })
    }

    function handleScroll(targets, path) {
        var observer = new IntersectionObserver(callbackObserver, options);
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 0,
          };

        function callbackObserver(entries) {
            entries.forEach(function(entry) {
              var element = entry.target;
              
              if (!entry.isIntersecting) return;
            
              var instance = setAnimate(element, path)
              instance.play()
              observer.unobserve(element)
            });
          }

        targets.each(function () {
            observer.observe($(this)[0])   
        })
    }
   

    arrDinignRoomsIcons.each(function () {
        switch ($(this).data('icon')) {
            case "corkscrew":
                var animateCorkIcon = setAnimate($(this)[0], pathCorkscrew)
                animate(animateCorkIcon,4000)
                break;
            case "barrel":
                var animateBarrelIcon = setAnimate($(this)[0], pathBarrel)
                animate(animateBarrelIcon,2000)
                break;
            case "triple":
                var animateTripleIcon = setAnimate($(this)[0], pathTriple)
                animate(animateTripleIcon,6000)
                break; 
            default:
              return
          }
    })

    var animateBurgerMenu = setAnimate(burgerIcon.container,burgerIcon.path)
    var animateHeaderLogo = setAnimate(headerLogo.container,headerLogo.path)
    animate(animateBurgerMenu, 5000)
    animate(animateHeaderLogo, 3000)
    handleHover(arrCardImgs, arrCards)
    handleScroll(menusMask.container,menusMask.path)

}


function initBackgroundVideos() {
    var menusModule = {
        videos: $('.menus .bg-video iframe'),
        parent: $('.menus')
    }
    var socialModule = {
        videos: $('.social .bg-video iframe'),
        parent: $('.social')
    }

    function setCoverViewport (props) {
        if (props.videos.length > 1) return;
        props.parent.addClass('has-bg-video')
        coverViewport(props.videos, false);
        $(window).on('resize', function () {
            coverViewport(props.videos, false);
        });
    }

    setCoverViewport(menusModule)
    setCoverViewport(socialModule)

}

// function customCookieBar() {
// 	if ( $('.jansz__cookie-bar').length > 0 ) {
// 		if ( !Cookies.get('accepted_cookies') ) {
// 			$('.jansz__cookie-bar').fadeIn();
// 		}

// 		$('.cookie-bar__accept-cta').on('click', function () {
// 			Cookies.set('accepted_cookies', 'true', {expires: $('.jansz__cookie-bar').data('notice-keep-hidden-for-n-days')});
// 			$('.jansz__cookie-bar').css('display', 'none')
// 		});
// 	}
// }